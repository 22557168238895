import { Injectable } from '@angular/core'
import * as colorConvert from 'color-convert'
import { Cmyk, ColorPickerService } from 'ngx-color-picker'
import { Objects } from '../../../../../server/src/shared-with-client/object-definitions'

@Injectable()
export class ColorService {
    constructor(private cpService: ColorPickerService) {}

    cmykDisplay(cmyk: Cmyk, cpService: ColorPickerService) {
        return cpService.denormalizeCMYK(cmyk)
    }

    cmykToHex(cmyk: Cmyk, cpService: ColorPickerService) {
        const rgba = cpService.cmykToRgb(cmyk)
        const normRgba = cpService.denormalizeRGBA(rgba)
        const hex = cpService.rgbaToHex(normRgba)
        return hex
    }

    colorObjectToHex(color: Objects.Color.Any, cpService: ColorPickerService | undefined) {
        return 'c' in color ? this.cmykToHex(color, cpService ?? this.cpService) : this.integerRgbaToHex(color, cpService ?? this.cpService)
    }

    getHex(color: Objects.Color.CMYK | Objects.Color.RGBA): string {
        if (this.isCMYK(color)) {
            return '#' + colorConvert.cmyk.hex([color.c, color.m, color.y, color.k])
        } else if (this.isRGBA(color)) {
            return '#' + colorConvert.rgb.hex([color.r, color.g, color.b])
        } else {
            return '#000000'
        }
    }

    isCMYK(color: Objects.Color.Any): color is Objects.Color.CMYK {
        const c: keyof Objects.Color.CMYK = 'c'
        const m: keyof Objects.Color.CMYK = 'm'
        const y: keyof Objects.Color.CMYK = 'y'
        const k: keyof Objects.Color.CMYK = 'k'

        if (!color) {
            return false
        }

        if (!Object.prototype.hasOwnProperty.call(color, c)) {
            return false
        }
        if (!Object.prototype.hasOwnProperty.call(color, m)) {
            return false
        }
        if (!Object.prototype.hasOwnProperty.call(color, y)) {
            return false
        }
        if (!Object.prototype.hasOwnProperty.call(color, k)) {
            return false
        }

        return true
    }

    isRGBA(color: Objects.Color.Any): color is Objects.Color.RGBA {
        const r: keyof Objects.Color.RGBA = 'r'
        const g: keyof Objects.Color.RGBA = 'g'
        const b: keyof Objects.Color.RGBA = 'b'
        const a: keyof Objects.Color.RGBA = 'a'

        if (!color) {
            return false
        }

        if (!Object.prototype.hasOwnProperty.call(color, r)) {
            return false
        }

        if (!Object.prototype.hasOwnProperty.call(color, g)) {
            return false
        }

        if (!Object.prototype.hasOwnProperty.call(color, b)) {
            return false
        }

        if (!Object.prototype.hasOwnProperty.call(color, a)) {
            return false
        }

        return true
    }

    colorObjectToRgb(color: Objects.Color.Any): Objects.Color.RGBA {
        if (this.isRGBA(color)) {
            return color
        } else {
            const rgb = colorConvert.cmyk.rgb([color.c, color.m, color.y, color.k])
            return { a: 1, r: rgb[0], g: rgb[1], b: rgb[2] }
        }
    }

    hexToCmyk(hex: string, cpService: ColorPickerService): Cmyk {
        const hsva = cpService.stringToHsva(hex)
        if (!hsva) {
            return {
                a: 0,
                c: 0,
                k: 0,
                m: 0,
                y: 0,
            }
        }
        const rgba = cpService.hsvaToRgba(hsva)
        const cmyk = cpService.rgbaToCmyk(rgba)
        return cmyk
    }

    hexToCmykString(hex: string, cpService: ColorPickerService): string {
        const cmykObj = this.hexToCmyk(hex, cpService)
        return this.cmykaObjectToCmykString(cmykObj)
    }

    hexToPercentRgba(hex: string, cpService: ColorPickerService | undefined): Objects.Color.RGBA {
        const hsva = this.cpService.stringToHsva(hex)
        if (!hsva) {
            return {
                r: 0,
                g: 0,
                b: 0,
                a: 1,
            }
        }
        const rgba = this.cpService.hsvaToRgba(hsva)
        return rgba
    }

    cmykStringToCmykObj(cmykString: string): Objects.Color.CMYK {
        const [c, m, y, k] = cmykString
            .replace('cmyk(', '')
            .replace(')', '')
            .split(',')
            .map((str) => parseFloat(str) / 100)
        return { c, m, y, k, a: 1 }
    }

    cmykaObjectToCmykString(cmykObject: Objects.Color.CMYK): string {
        const arr = [cmykObject.c, cmykObject.m, cmykObject.y, cmykObject.k]
        return `cmyk(${arr.map((num) => Math.round(num * 100)).join(',')})`
    }

    rgbaObjectToRgbString(rgbaObject: Objects.Color.RGBA): string {
        const arr = [rgbaObject.r, rgbaObject.g, rgbaObject.b]
        return `rgb(${arr.map((num) => Math.round(num * 255)).join(',')})`
    }

    hexToRgbString(hex: string, cpService: ColorPickerService): string {
        const rgba = this.hexToPercentRgba(hex, cpService)
        return this.rgbaObjectToRgbString(rgba)
    }

    percentRgbaToRgba(percentRgba: Objects.Color.RGBA): Objects.Color.RGBA {
        const arr = [percentRgba.r, percentRgba.g, percentRgba.b, percentRgba.a]
        const [r, g, b, a] = arr.map((num) => Math.round(num * 255))
        return { r, g, b, a }
    }

    rgbArrayToRgba(rgbArray: [number, number, number]): Objects.Color.RGBA {
        const [r, g, b] = rgbArray
        return { r, g, b, a: 255 }
    }

    percentRgbaToHex(percentRgba: Objects.Color.RGBA, cpService: ColorPickerService): string {
        const rgba = this.percentRgbaToRgba(percentRgba)
        return cpService.rgbaToHex(rgba)
    }

    integerRgbaToHex(rgba: Objects.Color.RGBA, cpService: ColorPickerService): string {
        return cpService.rgbaToHex(rgba)
    }

    rgbArrayToHex(rgbArray: [number, number, number], cpService: ColorPickerService): string {
        const rgba = this.rgbArrayToRgba(rgbArray)
        return cpService.rgbaToHex(rgba)
    }
}
